import React from "react";
import Header from "../Header/HeaderPage";
import { Outlet } from "react-router-dom";
import FooterPage from "../Footer/FooterPage";

const RootLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <main
        className=" px-2 min-h-screen overflow-y-auto"
        style={{ paddingTop: "50px", zIndex: 1, position: "relative" }}
      >
        <Outlet />
      </main>
      <FooterPage />
    </div>
  );
};

export default RootLayout;
