import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/blur.css";

const ImageWithLazyLoad = ({
  src,
  alt,
  className = "",
  style = {},
  placeholderSrc = "https://cloudinary-marketing-res.cloudinary.com/images/w_1000,c_scale/v1699909962/fallback_image_header/fallback_image_header-png?_i=AA",
  effect = "opacity",
  ...props
}) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    console.log("error loading message");
    setImageError(true);
  };

  const validSrc = imageError
    ? placeholderSrc
    : src && typeof src === "string" && src.trim() !== ""
    ? src
    : placeholderSrc;

  return (
    <LazyLoadImage
      src={validSrc}
      alt={alt}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className={className}
      effect={effect}
      placeholderSrc={placeholderSrc}
      onError={handleImageError}
      {...props}
    />
  );
};

export default ImageWithLazyLoad;
