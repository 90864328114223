import React from "react";
import { Link } from "react-router-dom";
import { logo } from "../../../asset/index";

const Header = () => {
  return (
    <header
      className="d-flex align-items-center px-3 position-fixed w-100 shadow-sm "
      style={{ height: "60px", top: 0, zIndex: 1030, background: "#fff" }}
    >
      <Link to={"/trang-chu"} className="link-custom d-flex align-items-center">
        <img
          src={logo}
          alt="img header"
          className="me-2"
          style={{ width: "50px", height: "50px" }}
        />
        <span className="fw-normal fs-4 d-none d-md-inline custom-transition text-uppercase">
          giúp việc mt
        </span>
      </Link>
    </header>
  );
};

export default Header;
