/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import {
  introductionImg,
  roomAndServiceImg1,
  roomAndServiceImg2,
  sliderImags,
  context01,
  service02,
  service03,
  service04,
  service01,
} from "../asset";

const tourSupportServiceList = [
  {
    id: "1",
    imge: service01,
    titleVNI: "Giúp việc theo giờ",
    titleENG: "",
  },
  {
    id: "2",
    imge: service02,
    titleVNI: "Giúp việc theo gói",
    titleENG: "",
  },
  {
    id: "3",
    imge: service03,
    titleVNI: "Vệ sinh công nghiệp sau xây dựng",
    titleENG: "",
  },
  {
    id: "4",
    imge: service04,
    titleVNI: "Vệ sinh cơ quan tổ chức theo hợp đồng",
    titleENG: "",
  },
];

const freeServiceList = [
  {
    id: "1",
    contentVNI:
      "Giúp việc dọn dẹp nhà cửa, vệ sinh các khu vực trong nhà, cửa hàng, trụ sở, văn phòng theo giờ hoặc theo định kỳ",
    contentENG: "",
  },
  {
    id: "2",
    contentVNI: "Vệ sinh công nghiệp sau xây dựng",
    contentENG: "",
  },
  {
    id: "3",
    contentVNI: "Nấu ăn và chuẩn bị bữa ăn (nếu có yêu cầu)",
    contentENG: "",
  },
  {
    id: "4",
    contentVNI:
      "Giúp việc trước - sau các buổi lễ, tiệc, cỗ của gia đình và cơ quan, như sinh nhật, cưới, hỏi, khai trương",
    contentENG: "",
  },
  {
    id: "5",
    contentVNI: "Giúp việc, hỗ trợ kiểm đếm, phân loại kho hàng, cửa hàng",
    contentENG: "",
  },
  {
    id: "6",
    contentVNI: "Giúp việc, hỗ trợ đóng gói, sắp xếp kho hàng, cửa hàng.",
    contentENG: "",
  },
  {
    id: "7",
    contentVNI: "Giúp việc đóng gói, chuyển nhà",
    contentENG: "",
  },
  {
    id: "8",
    contentVNI:
      "Giúp việc đón, trông trẻ giúp phụ huynh trong khung giờ bận rộn, hoặc có việc đột xuất",
    contentENG: "",
  },
];

const roomAndServiceList = [
  {
    id: 1,
    image: roomAndServiceImg2,
    titleVNI: "Tải app và đăng ký tài khoản trên ios",
    titleENG: "",
    contentVNI: "",
    contentENG: "",
    link_store:"https://apps.apple.com/us/app/gi%C3%BAp-vi%E1%BB%87c-mt/id6737520071"
  },
  {
    id: 2,
    image: roomAndServiceImg1,
    titleVNI: "Tải và đăng ký tài khoản trên androi",
    titleENG: "",
    contentVNI: "",
    contentENG: "",
    link_store:"https://play.google.com/store/apps/details?id=com.giucviec.taybac"

  },
];

const introductionBlog = {
  id: "1",
  img: introductionImg,
  title: "App mobile androi and ios",
  contentVNI:
    "Giúp Việc Mt là ứng dụng kết nối dịch vụ giúp việc theo giờ và theo gói, đáp ứng mọi nhu cầu của bạn trong cuộc sống bận rộn. Dù là dọn dẹp nhà cửa, hỗ trợ đóng gói hàng hóa, hay chuẩn bị trước và sau các buổi tiệc... Đặt lịch dễ dàng, chọn gói dịch vụ phù hợp, và tận hưởng không gian sống gọn gàng, thoải mái. Đặc biệt, Mt còn hỗ trợ xuất hóa đơn cho các tổ chức, doanh nghiệp sử dụng dịch vụ theo gói, giúp bạn thuận tiện trong việc chứng từ chi phí.",
  contentENG: "",
};

const contextBlog = {
  id: "1",
  img: context01,
  title: "HỢP TÁC CUNG CẤP DỊCH VỤ TRÊN ỨNG DỤNG ĐỘC QUYỀN TẠI MỖI TỈNH/ THÀNH",
  contentVNI:
    "Công ty TNHH MTV MT Tây bắc trân trọng kính mời đối tác trở thành nhà cung cấp độc quyền tại Tỉnh/ thành của mình, cùng chúng tôi phát triển ứng dụng cung cấp dịch vụ giúp việc theo giờ, theo gói.",
  contentENG: "",
};

// eslint-disable-next-line
export const useHomepageController = () => {
  const [tourSupportServices, setSupportServices] = useState(
    tourSupportServiceList
  );
  const [freeServices, setFreeServices] = useState(freeServiceList);
  const [roomsAndServices, setRoomAndService] = useState(roomAndServiceList);
  const [introduction, setIntroduction] = useState(introductionBlog);
  const [sliderImages, setSliderImages] = useState(sliderImags);
  const [context, setContextBlog] = useState(contextBlog);

  return {
    tourSupportServices,
    freeServices,
    roomsAndServices,
    introduction,
    sliderImages,
    context,
  };
};
