import React from "react";

import ImageWithLazyLoad from "../ImageLoad/ImageWithLazyLoad";

const CardItem = ({ roomAndService,  }) => {
  return (
    <>
      <div
        className="card"
        style={{ border: "none", cursor: "pointer" }}
        onClick={() => {
          window.open(roomAndService.link_store, "_blank");

        }} // Gọi hàm onClick nếu có
      >
        <ImageWithLazyLoad
          src={roomAndService.image}
          style={{ maxWidth: "550px", maxHeight: "550px" }}
          className="card-img-top object-fit-cover rounded-0"
          placeholderSrc="https://via.placeholder.com/150"
          alt="Rom and service img"
          wrapperProps={{
            style: { transitionDelay: "1s" },
          }}
        />
        <div className="card-body mt-4">
          <div className="card-title text-center gap-1">
            <h5 className="custom-title-vni text-uppercase">
              {roomAndService.titleVNI}
            </h5>
            <h5 className="custom-title-eng">{roomAndService.titleENG}</h5>
          </div>

          <div className="card-text mt-3 text-center">
            <span className="custom-content-vni ">
              {roomAndService.contentVNI}
            </span>
            <span className="m-2"> {roomAndService.contentVNI ? "-" : ""}</span>
            <span className="custom-content-eng ">
              {roomAndService.contentENG}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardItem;
