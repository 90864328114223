import React from "react";
import { FaFacebook, FaTiktok } from "react-icons/fa";

const FooterPage = () => {
  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-12 col-md-6 my-4">
          <h5>Liên hệ:</h5>
          <h5>Hỗ trợ đặt lịch dịch vụ: 0979534501</h5>
          <h5>Hỗ trợ tạo tài khoản trên app: 0832901396</h5>
          <h5>Hợp tác độc quyền các tỉnh: 0977773299</h5>
        </div>
        <div className="col-12 col-md-6 my-4">
          <div
            className="d-flex justify-content-center align-items-center gap-3"
            style={{ height: "100%" }}
          >
            <div className="icon-container">
              <a
                href="https://www.facebook.com/giupviecmt"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook size={20} color="#fff" />
              </a>
            </div>
            <div className="icon-container">
              <a
                href="https://www.facebook.com/giupviecmt"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTiktok size={20} color="#fff" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 mt-5 d-flex d-sm-block justify-content-center my-4">
          <div
            className="ratio ratio-1x1 "
            style={{
              maxWidth: "500px",
              maxHeight: "500px",
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3686.6139258275416!2d103.96737!3d22.481138!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x36cd1478b7833587%3A0xc31e091ec05c630e!2zMTkwIE5o4bqhYyBTxqFuLCBLaW0gVMOibiwgTMOgbyBDYWksIFZp4buHdCBOYW0!5e0!3m2!1svi!2sus!4v1733124214590!5m2!1svi!2sus"
              title="map"
              target="_blank"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterPage;
