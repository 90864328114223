import introductionImg from "./images/session-01.jpg";

import roomAndServiceImg1 from "./images/img-card-01.jpg";
import roomAndServiceImg2 from "./images/img-card-02.jpg";

import context01 from "./images/context-01.jpg";

import service01 from "./images/service-01.jpg";
import service02 from "./images/service-02.jpg";
import service03 from "./images/service-03.jpg";
import service04 from "./images/service-04.jpg";

import logo from "./images/logo.jpg";

const sliderImags = [introductionImg];

export {
  sliderImags,
  introductionImg,
  roomAndServiceImg1,
  roomAndServiceImg2,
  logo,
  context01,
  service01,
  service02,
  service03,
  service04,
};

//
