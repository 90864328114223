import React from "react";

import {
  CardItem,
  CardSupportTour,
  ImageWithLazyLoad,
  SliderShowImg,
} from "../../components";
import { useHomepageController } from "../../../controller/homepageController";

const HomePage = () => {
  const {
    tourSupportServices,
    freeServices,
    roomsAndServices,
    introduction,
    sliderImages,
    context,
  } = useHomepageController();

  return (
    <div className="container mt-5">
      <section className="text-center">
        <h1
          className=" fw-normal lh-base text-uppercase "
          style={{ fontSize: "clamp(40px, 5vw, 64px)", lineHeight: "1.2" }}
        >
          Giúp việc mt
        </h1>

        <div className="custom-border-header"></div>
      </section>

      {/* intro */}
      <section className="row my-5 ">
        <div className="col-sm-12 col-12 col-md-6 text-center mb-2">
          <ImageWithLazyLoad
            src={introduction.img}
            alt="introduction image"
            className="object-fit-cover w-100"
            style={{ maxWidth: "500px", maxHeight: "500px" }}
          />
        </div>
        <div className="col-sm-12 col-12 col-md-6 mb-2">
          <div className="text-center">
            <h1 className="text-uppercase fw-normal text-break lh-base">
              {introduction.title}
            </h1>
          </div>

          <div className="lh-lg text-align-left">
            <span className="custom-content-vni">
              {introduction.contentVNI}
            </span>
            {introduction.contentENG === "" ? (
              ""
            ) : (
              <span className="separator"> / </span>
            )}
            <span className="custom-content-eng">
              {introduction.contentENG}
            </span>
          </div>
        </div>
      </section>

      {/* free services */}
      <section>
        <div className="lh-lg text-align-left">
          {freeServices?.map((object, index) => {
            return (
              <ul key={index} className="">
                <li style={{ listStyleType: "square" }}>
                  <span className="custom-content-vni">
                    {object.contentVNI}
                  </span>
                  {object.contentENG === "" ? (
                    ""
                  ) : (
                    <span className="separator"> / </span>
                  )}
                  <span className="custom-content-eng">
                    {object.contentENG}
                  </span>
                </li>
              </ul>
            );
          })}
          <i className="custom-content-vni">
            Hoặc bất kể công việc gì chúng tôi có thể giúp bạn, hãy đưa ra yêu
            cầu nhé
          </i>
        </div>
      </section>

      {/* <div className="custom-border-content"></div> */}

      {/* room and service */}
      <section className="my-5">
        <div className="row justify-content-center">
          {roomsAndServices.map((obj, index) => {
            return (
              <div
                className="col-12 col-sm-8 col-md-6 mb-4 d-flex justify-content-center"
                key={index}
              >
                <CardItem roomAndService={obj} />
              </div>
            );
          })}
        </div>
      </section>

      {/* context blog */}
      <section className="row my-5 ">
        <div className="col-sm-12 col-12 col-md-6 text-center mb-2">
          <ImageWithLazyLoad
            src={context.img}
            alt="introduction image"
            className="object-fit-cover w-100"
            style={{ maxWidth: "500px", maxHeight: "500px" }}
          />
        </div>
        <div className="col-sm-12 col-12 col-md-6 mb-2">
          <div className="text-center">
            <h1 className="text-uppercase fw-normal text-break lh-base">
              {context.title}
            </h1>
          </div>

          <div className="lh-lg text-align-left">
            <span className="custom-content-vni">{context.contentVNI}</span>
            {context.contentENG === "" ? (
              ""
            ) : (
              <span className="separator"> / </span>
            )}
            <span className="custom-content-eng">{context.contentENG}</span>
          </div>
        </div>
      </section>

      {/* card support tour */}
      <section className="my-5">
        <div className="row p-">
          {tourSupportServices.map((obj, index) => {
            return (
              <div className="col-md-3 col-12 col-sm-6" key={index}>
                <CardSupportTour service={obj} />
              </div>
            );
          })}
        </div>
      </section>

      {/* video */}
      <section className="my-5">
        <div className="row">
          <div className="col-md-6 col-12 d-flex d-sm-block justify-content-center">
            <div
              className="ratio ratio-1x1"
              style={{ maxWidth: "500px", maxHeight: "500px" }}
            >
              <iframe
                src="https://youtube.com/embed/67GxwFZJWJk?si=udCuRYFVGZySPzIy"
                title="YouTube video player"
                target="_blank"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      {/* button */}
      <div className=" my-5">
        <div className="row justify-content-center justify-content-md-start">
          <div className="col-6 col-md-2 mb-2">
            <a
              href="https://zalo.me/0977773299"
              className="btn btn-danger btn-lg w-100 p-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              Zalo
            </a>
          </div>

          <div className="col-6 col-md-2 mb-2">
            <a
              href="https://api.whatsapp.com/send/?phone=84977773299&text&type=phone_number&app_absent=0"
              className="btn btn-danger btn-lg w-100 p-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              WhatsApp
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
