import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isCrashSystem: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLoginData: (state, action) => {
      state.user = action.payload;
    },
    setIsCrashSystem: (state, action) => {
      state.crashSystem = action.payload;
    },
  },
});

export const { setUserLoginData, setIsCrashSystem } = userSlice.actions;
export const userReducer = userSlice.reducer;
