import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { RootLayout } from "./views/components/index";
import { HomePage } from "./views/pages";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route index element={<HomePage />} />
          <Route path="trang-chu" element={<HomePage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
