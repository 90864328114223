import React from "react";
import ImageWithLazyLoad from "../ImageLoad/ImageWithLazyLoad";

const CardSupportTour = ({ service }) => {
  return (
    <div className="card d-flex align-items-center" style={{ border: "none" }}>
      <ImageWithLazyLoad
        src={service.imge}
        style={{ maxWidth: "300px", maxHeight: "300px" }}
        className="card-img-top object-fit-cover rounded-0"
        alt="service img"
      />
      <div className="card-body text-center mt-2">
        <span className="custom-content-vni text-uppercase">
          {service.titleVNI}
        </span>
        {service.titleENG === "" ? "" : <span className="separator"> / </span>}
        <span className="custom-content-eng">{service.titleENG}</span>
      </div>
    </div>
  );
};

export default CardSupportTour;
